<template>
  <v-container :fluid="$vuetify.breakpoint.mdAndDown"
    class="px-10"
  >
    <v-row>
      <v-col>
        <h1 class="mt-5">メールアドレス管理</h1>
        <banner-hint>
          登録したメールアドレスへは「店舗管理」→「メルマガ送信」からメールが送信できる様になります。<br />
          一件ずつ登録、もしくはCSVファイルからメールアドレスのリストをインポート（一括追加）ができます。
        </banner-hint>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="6" class="d-flex align-self-stretch">
        <v-card
          class="my-5 pa-5 px-7 flex-grow-1"
          elevation="1"
        >
          <v-form :ref="'form-email'">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model.trim="addressInput"
                  required
                  label="メールアドレス"
                  :rules="[valiRules.required, valiRules.email, valiRules.max100]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters class="mt-5">
              <v-spacer></v-spacer>
              <v-btn
                depressed small
                color="primary"
                @click="createEmail()"
              >登録</v-btn>
            </v-row>
          </v-form>
        </v-card>
      </v-col>

      <v-col cols="12" sm="6">
        <v-card
          class="my-5 pt-7 pb-5 px-7"
          elevation="1"
        >
          <v-overlay
            absolute
            opacity=0.8
            :value="shopData.system_plan_id < 2"
          >
            <icon-info icon="information" class="ml-n1 mt-3 mb-9" :square="true">
              {{ csvUableMessage }}
            </icon-info>
          </v-overlay>

          <!-- CSVインポート -->
          <v-form :ref="'form-emails'">
            <v-row>
              <v-col cols="12">
                <v-file-input
                  v-model="csvUploaded"
                  accept=".csv"
                  outlined
                  chips
                  required
                  show-size
                  prepend-icon="mdi-microsoft-excel"
                  label="CSVファイルを選択"
                  persistent-hint
                  :rules="[valiRules.required, valiRules.file]"
                >
                  <template v-slot:selection="{ text }">
                    <v-chip
                      color="primary"
                      label small
                    >
                      {{ text }}
                    </v-chip>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <icon-info icon="alert" class="ml-n3" :square="true">
                メールアドレスのみを含むCSVを使用してください。<br />
                エラーが出る場合はサポートまでご連絡ください。
              </icon-info>
              <v-spacer></v-spacer>
              <v-btn
                depressed small
                color="primary"
                @click="batchImport()"
              >インポート</v-btn>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>

    <!-- メアドリスト -->
    <v-row>
      <v-col cols="12">
        <v-card flat tile>
          <table>
            <thead>
              <tr>
                <th width="60%">メールアドレス（ユニーク件数 {{ emailCount }}）</th>
                <!-- <th>会員番号</th> -->
                <th width="30%">登録日</th>
                <!-- <th width="10%">有効性</th> -->
                <th width="10%">削除</th>
              </tr>
            </thead>
            <tbody v-if="emails.length" >
              <tr
                v-for="(email, index) in emails"
                :key="email.email_id"
              >
                <td>{{ email.email_address }}</td>
                <td>{{ formatDate(email.created_at) }}</td>
                <!-- <td>{{ mailValidity(email.is_valid) }}</td> -->
                <td align="center">
                  <v-btn
                    text x-small
                    color="primary"
                    @click="openModalDelete(index)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </table>
        </v-card>

        <v-card v-if="!emails.length" flat>
          <v-card-text>メールアドレスが登録されていません。</v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- 削除モーダル -->
    <modal-delete ref="modalDelete">
      <div v-html="modalMessage"></div>
    </modal-delete>

    <!-- バリデーションアラート -->
    <v-snackbar
      v-model="snackbar.open"
      :timeout="3000"
      :color="snackbar.color"
      top
    >
      {{ snackbar.message }}
    </v-snackbar>

    <!-- ローダー -->
    <loader
      :loading="loading"
      :absolute="false"
    >
      {{ loadingMessage }}
    </loader>
  </v-container>
</template>

<!-- ************************************* -->
<!-- ************* スクリプト ************** -->
<!-- ************************************* -->
<script>
import moment from 'moment'
import $literals from '@/literals.js'
import { ApiTool, CheckTokenError, ValidationRules } from '@/module.js'
import Loader from '@/components/_Loader.vue'
import BannerHint from "@/components/_BannerHint.vue";
import IconInfo from "@/components/_IconInfo.vue";
import ModalDelete from '@/components/_ModalDelete.vue'

export default {
  components: {
    'loader': Loader,
    'banner-hint': BannerHint,
    'icon-info': IconInfo,
    'modal-delete': ModalDelete,
  },

  props: {
    apiAdmin: {
      type: String,
      required: true
    },
    shopData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      emails: [],
      emailCount: 0,
      csvUploaded: null,
      addressInput: '',
      modalMessage: '',
      csvUableMessage: $literals.MESSAGE.availableForPaidPlanShort,
      loading: false,
      loadingMessage: '',
      valiRules: ValidationRules,
      snackbar: {open: false, color: 'primary', message: ''},
      adminApi: new ApiTool(this.apiAdmin, this.shopData),
    }
  },

  computed: {
    serverToken() {
      return sessionStorage.getItem('serverToken')
    },
    mailValidity() {
      return isValid => isValid ? '◎' : '✖︎'
    },
    formatDate() {
      return date => moment(date).format('YYYY年MM月DD日')
    }
  },

  //***************************************************
  //ライフサイクル
  //***************************************************
  created() {
    this.adminApi.setToken(this.serverToken)

    this.loading = true
    this.loadingMessage = $literals.MESSAGE.loaderLoading

    this.getData()
    .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
    .then(() => this.loading = false)
  },

  //***************************************************
  //メソッド
  //***************************************************
  methods: {
    async getData() {
      await Promise.all([
        this.getEmails(),
        this.getEmailCount(),
      ])
    },

    //削除クリック
    openModalDelete(index) {
      this.modalMessage = '<p>以下のメールアドレスを削除してよろしいですか？</br></br>' + this.emails[index].email_address + '</p>'

      const modalHanddown = {
        submitCallback: this.deleteEmail,
        comeBack: { index: index }
      }
      this.$refs.modalDelete.open(modalHanddown)
    },

    //**************************************************
    //**************************************************
    //                    APIコール
    //**************************************************
    //**************************************************
    //メールアドレス取得
    //**************************************************
    getEmails() {
      return this.adminApi.getReqWithAuth('email/').then( records => {
        if (!records || !records.length) return
        this.emails = records
      })
    },

    //**************************************************
    //カウント取得
    //**************************************************
    getEmailCount() {
      return this.adminApi.getReqWithAuth('email/count/').then( result => {
        this.emailCount = result
      })
    },

    //**************************************************
    // CSVインポート
    //**************************************************
    batchImport() {
      if (!this.$refs['form-emails'].validate()) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFormInput, open: true}}
        return
      } else if (this.shopData.system_plan_id < 2) {
        this.snackbar = {...{color:'error', message: $literals.MESSAGE.availableForPaidPlanShort, open: true}}
        return
      }

      this.loading = true
      this.loadingMessage = $literals.MESSAGE.loaderInTarnsaction

      const formData = new FormData()
      formData.append('upload_file', this.csvUploaded)

      this.adminApi.apiReqWithData('POST', 'create/emails/', formData).then(() => {
        this.getData()
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.loading = false )
    },

    //**************************************************
    // 1件新規登録
    //**************************************************
    createEmail() {
      if (!this.$refs['form-email'].validate()) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFormInput, open: true}}
        return
      }
      if (this.emails.findIndex( email => email.email_address === this.addressInput ) >= 0) {
        this.snackbar = {...{color:'info', message: $literals.MESSAGE.infoDataExists, open: true}}
        return
      }

      this.loading = true
      this.loadingMessage = $literals.MESSAGE.loaderInTarnsaction

      const apiPartial = 'create/email/'
      const payload = JSON.stringify({email_address: this.addressInput})

      this.adminApi.apiReqWithData('POST', apiPartial, payload).then( response => {
        this.emails.push({
          email_id: response.email_id,
          email_address: this.addressInput,
          is_valid: true,
          created_at: moment(new Date()).format()
        })
        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successCreateSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.loading = false )
    },

    //**************************************************
    //削除
    //**************************************************
    deleteEmail(comeBack) {
      const apiPath = 'delete/email/' + this.emails[comeBack.index].email_id

      this.adminApi.apiReqWithData('DELETE', apiPath).then(() => {
        this.emails.splice(comeBack.index, 1)
        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successDeleteSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.$refs.modalDelete.close() )
    },

    //**************************************************
    //一括削除
    //**************************************************
    batchDelete() {
      const apiPath = 'delete/emails/'

      this.adminApi.apiReqWithData('DELETE', apiPath).then(() => {
        this.emails.length = 0
        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successDeleteSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.$refs.modalDelete.close() )
    }
  }
}
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  border: 1px solid #aaa;
}
thead tr {
  border: 1px solid #aaa;
  background: var(--v-primary-base);
}
tbody tr:nth-child(even){
  background-color: #e7e7e7;
}
.theme--dark tbody tr:nth-child(even){
  background-color: #111;
}
th {
  font-size: .9em;
  padding: 2px 0;
  text-align: center;
  border-right:solid 1px #aaa;
  border-left:solid 1px #aaa;
}
td {
  padding-left: 5px;
  border-right:solid 1px #aaa;
  border-left:solid 1px #aaa;
  color: #666;
}
td .v-icon {
  font-size: 20px;
}
</style>
